import React, { useEffect } from "react";
import { Nav } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";

export default function Meeting() {
  const { state } = useLocation();

  useEffect(() => {
    let startTime = state.timer;
    let targetTime = new Date(startTime + 50 * 60000).getTime();

    console.log(targetTime);

    let timerString = document.getElementById("timer-string");
    if (!timerString) {
      return;
    }

    let x = setInterval(() => {
      let now = new Date().getTime();
      let dist = targetTime - now;
      let minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((dist % (1000 * 60)) / 1000);
      timerString.innerHTML =
        String(minutes).padStart(2, "0") +
        " : " +
        String(seconds).padStart(2, "0");
      if (dist <= 0) {
        clearInterval(x);
        timerString.innerHTML = "00 : 00";
      }
      document.title = timerString.innerHTML + " | Sprint";
    }, 500);
    return () => {
      clearInterval(x);
    };
  }, [state]);

  return (
    <>
      <div
        className="d-flex flex-row justify-content-center align-items-center"
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          right: "0",
          height: "80px",
        }}
      >
        <div className="d-flex flex-row justify-content-between w-100">
          <div className="">
            <Nav.Link
              as={Link}
              to="/"
              className="mx-3 text-primary"
              style={{ fontSize: "24px" }}
            >
              <FontAwesomeIcon
                icon={faCircleArrowLeft}
                className="text-primary me-2"
              />
              Back
            </Nav.Link>
          </div>
          <div id="timer-string" style={{ fontSize: "28px" }} className="me-5">
            50 : 00
          </div>
          <div></div>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          top: "80px",
          left: "0",
          right: "0",
          bottom: "0",
        }}
      >
        <iframe
          src={"https://meet.jit.si/" + state.room}
          height="100%"
          width="100%"
          title="Iframe Example"
          allow="camera;microphone"
          frameBorder="0"
        ></iframe>
      </div>
    </>
  );
}
