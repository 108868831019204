import React, { useRef, useState, useEffect } from "react";
import DashboardContainer from "./DashboardContainer";
import { Button, Card, Row, Col, Form, Alert } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { updateProfile } from "firebase/auth";

export default function Profile() {
  const usernameRef = useRef();
  const [showAlert, setShowAlert] = useState(false);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    usernameRef.current.value = currentUser.displayName;
  }, [currentUser.displayName]);

  async function handleLogout() {
    await logout();
    navigate("/login");
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      await updateProfile(currentUser, {
        displayName: usernameRef.current.value,
      });
      setShowAlert(true);
    } catch {}
  }

  return (
    <DashboardContainer title="Profile">
      <Row>
        <Col xs={12} lg={6}>
          <Card>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder={currentUser.email}
                    disabled
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formUsername">
                  <Form.Label>Username</Form.Label>
                  <Form.Control placeholder="" ref={usernameRef} required />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Update Profile
                </Button>
              </Form>
              {showAlert && (
                <Alert className="mt-3" variant="success">
                  Profile updated
                </Alert>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} lg={6}>
          <Card>
            <Card.Body>
              <Form>
                <Form.Group className="mb-2" controlId="formLogout">
                  <Form.Label>Sign out</Form.Label>
                </Form.Group>
                <Button onClick={handleLogout}>Log Out</Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </DashboardContainer>
  );
}
