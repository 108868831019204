import React, { useEffect } from "react";
import NavbarComponent from "./NavbarComponent";
import { Container, Row, Col } from "react-bootstrap";

export default function DashboardContainer(props) {
  useEffect(() => {
    document.title = props.title + " | Sprint";
  });

  return (
    <div style={{ background: "#F4F4F3", minHeight: "100vh" }}>
      <NavbarComponent />

      <Container className="mt-3">
        <Row>
          <Col>
            <h1 style={{ fontSize: "26px" }} className="fw-bold py-3">
              {props.title}
            </h1>
          </Col>
        </Row>
        {props.children}
        <Row className="mt-4">
          <Col>
            Got feedback?{" "}
            <a
              href="mailto:contact@pipewing.com"
              style={{ textDecoration: "None" }}
            >
              contact@pipewing.com
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
