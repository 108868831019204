import React, { useRef, useState, useEffect } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import CenteredContainer from "./CenteredContainer";
import { useNavigate } from "react-router-dom";

export default function Signup() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmlRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Signup | Pipewing";
  });

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.value !== passwordConfirmlRef.value) {
      return setError("Passwords do not match");
    }

    try {
      setError("");
      setLoading(true);
      await signup(emailRef.current.value, passwordRef.current.value);
      navigate("/");
    } catch {
      setError("Failed to create an account");
    }

    setLoading(false);
  }

  return (
    <CenteredContainer>
      <div className="d-flex flex-row align-items-center justify-content-center mb-5">
        <img
          src="/pipewing-logo-wide.svg"
          style={{ width: "180px", height: "auto" }}
          alt="logo"
        />
      </div>
      <Card>
        <Card.Body>
          <h2
            className="text-center mb-4 pt-2 fw-bold"
            style={{ fontSize: "24px" }}
          >
            Sign Up
          </h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email" className="mt-3">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="password" className="mt-3">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>
            <Form.Group id="password-confirm" className="mt-3">
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control
                type="password"
                ref={passwordConfirmlRef}
                required
              />
            </Form.Group>
            <Form.Check
              className="pt-3"
              id="tos"
              inline
              required
              label={
                <span>
                  I have read and agree to the{" "}
                  <a
                    href="https://pipewing.com/privacy-policy/"
                    rel="noreferrer"
                    target="_blank"
                    style={{ textDecoration: "None" }}
                  >
                    Privacy Policy
                  </a>
                  , the{" "}
                  <a
                    href="https://pipewing.com/terms-of-service/"
                    rel="noreferrer"
                    target="_blank"
                    style={{ textDecoration: "None" }}
                  >
                    Terms of Service
                  </a>
                  , the{" "}
                  <a
                    href="https://pipewing.com/cookie-policy/"
                    rel="noreferrer"
                    target="_blank"
                    style={{ textDecoration: "None" }}
                  >
                    Cookie Policy
                  </a>{" "}
                  and the{" "}
                  <a
                    href="https://pipewing.com/acceptable-use-policy"
                    rel="noreferrer"
                    target="_blank"
                    style={{ textDecoration: "None" }}
                  >
                    Acceptable Use Policy
                  </a>
                  .
                </span>
              }
              name="group1"
              type="checkbox"
            />
            <Button className="w-100 mt-4" type="submit" disabled={loading}>
              Sign Up
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        Already have a login? <Link to="/login">Log In</Link>
      </div>
    </CenteredContainer>
  );
}
