import React, { useState, useEffect } from "react";
import DashboardContainer from "./DashboardContainer";
import { Button, Card, Row, Col } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase";
import { set, ref, onValue, onDisconnect, remove } from "firebase/database";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [hasOpenSprint, setHasOpenSprint] = useState(false);
  const [sprints, setSprints] = useState([]);

  useEffect(() => {
    // Lobby table data
    onValue(ref(db, "/sprints"), (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        if (data.hasOwnProperty(currentUser.uid)) {
          setHasOpenSprint(true);
        }
      }
      onDisconnect(ref(db, "/sprints/" + currentUser.uid)).remove();
      setSprints(data);
    });

    // Listen if someone joins a sprint
    onValue(ref(db, "/joins/" + currentUser.uid), (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        deleteSprint();
        deleteJoin();
        // createSprintTimer();
        navigate("/meeting", {
          state: {
            timer: new Date().getTime(),
            room: data.room,
          },
        });
      }
      onDisconnect(ref(db, "/joins/" + currentUser.uid)).remove();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.uid]);

  // const createSprintTimer = () => {
  //   set(ref(db, "/timers/" + currentUser.uid), {
  //     start: Date.now(),
  //   });
  //   onDisconnect(ref(db, "/timers/" + currentUser.uid)).remove();
  // };

  const createSprint = () => {
    if (currentUser.displayName) {
      set(ref(db, "/sprints/" + currentUser.uid), {
        user: currentUser.displayName,
        test2: {},
      });
    } else {
      set(ref(db, "/sprints/" + currentUser.uid), {
        user: "Anonymous",
        test2: {},
      });
    }
    setHasOpenSprint(true);
  };

  const deleteSprint = () => {
    remove(ref(db, "/sprints/" + currentUser.uid));
    setHasOpenSprint(false);
  };

  const joinSprint = (otherUser) => {
    const roomId = Math.floor(Math.random() * 10 ** 12);
    deleteSprint();
    set(ref(db, "/joins/" + otherUser), {
      user: otherUser,
      joinedBy: currentUser.uid,
      room: roomId,
    });
    setHasOpenSprint(false);
    // createSprintTimer();
    navigate("/meeting", {
      state: {
        timer: new Date().getTime(),
        room: roomId,
      },
    });
  };

  const deleteJoin = () => {
    remove(ref(db, "/joins/" + currentUser.uid));
  };

  return (
    <DashboardContainer title="Lobby">
      <Row>
        <Col xs={12}>
          <Card>
            <table className="table mb-0">
              <thead>
                <tr>
                  <th scope="col">Username</th>
                  <th scope="col">Time</th>
                </tr>
              </thead>
              <tbody>
                {sprints &&
                  Object.keys(sprints).map((x) => {
                    if (x === currentUser.uid) {
                      return (
                        <tr
                          key={x}
                          className="bg-light"
                          style={{ cursor: "pointer" }}
                          onClick={deleteSprint}
                        >
                          <td>{sprints[x].user}</td>
                          <td>50 min</td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr
                          key={x}
                          className="hoverHighlight"
                          style={{ cursor: "pointer" }}
                          onClick={() => joinSprint(x)}
                        >
                          <td>{sprints[x].user}</td>
                          <td>50 min</td>
                        </tr>
                      );
                    }
                  })}
              </tbody>
            </table>
          </Card>
        </Col>
        <Col xs={12}>
          <div className="my-2">Click on a username to join a work sprint.</div>
        </Col>
        <Col xs={12}>
          <div className="my-4">
            {!hasOpenSprint && (
              <Button onClick={createSprint}>Create Sprint</Button>
            )}
            {hasOpenSprint && (
              <Button onClick={deleteSprint}>Cancel Sprint</Button>
            )}
          </div>
        </Col>
      </Row>
    </DashboardContainer>
  );
}
