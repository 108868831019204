import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faHouse } from "@fortawesome/free-solid-svg-icons";

export default function NavbarComponent() {
  return (
    <Navbar bg="white" className="border-bottom">
      <Container>
        <Nav.Link as={Link} to="/">
          <img
            src="/pipewing-logo-wide.svg"
            style={{ width: "120px", height: "auto" }}
            alt="logo"
          />
        </Nav.Link>
        <Nav style={{ fontSize: "22px" }}>
          <Nav.Link as={Link} to="/" className="me-2">
            <FontAwesomeIcon icon={faHouse} className="text-primary" />
          </Nav.Link>
          <Nav.Link as={Link} to="/profile">
            <FontAwesomeIcon icon={faUser} className="text-primary" />
          </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
}
